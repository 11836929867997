/* Reset default margin and padding for the entire page */
body,
h1,
h2,
h3,
p,
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply custom styles to the slider */
.allover-container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
  background-color: #0d0624;
}
.crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl .cc-nsge .cc-imbb {
  display: block !important;
}
.MuiPaper-root
  .MuiPaper-elevation
  .MuiPaper-rounded
  .MuiPaper-elevation8
  .MuiPopover-paper
  .MuiMenu-paper
  .MuiMenu-paper
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #140c2d !important;
  box-shadow: 0 0 1px #000000 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: #140c2d !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.css-r8u8y9 {
  background-color: #140c2d !important;
}
.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  background-color: #0d0624;
}
.pay-cards {
  width: 80%;
}

.payment-skelton{
  width: 70%
}
.chat-responsive {
  justify-content: space-between;
}
/* [data-player] {
  width: 100% !important;
  height: 90vh !important;
} */
.action-dot {
  visibility: hidden;
}
.msg-actions:hover .action-dot {
  visibility: visible;
}
.action-box {
  border-radius: 6px;
}
.action-box1 {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 0px !important;
}
.action-box2 {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0px !important;
}
.action-hover:hover {
  background-color: #0d0624;
  transition: 0.1s ease-in-out;
}

li a {
  text-decoration: none;
}
.logooo {
  display: none;
}
aside {
  width: 100% !important;
  height: 100% !important;
}
.epr-header {
  display: none !important;
}
.epr-skin-tones {
  display: none !important;
}
.epr-main
  .EmojiPickerReact
  .epr_mw4zr1
  .epr_q53mwh
  .epr_7hyle2
  .epr_-x928hz
  .epr_-rvl3je
  .epr_-khp9xq
  .epr_-to8mm
  .epr_-4ueg5a
  .epr_iddbfm
  .epr_vy4vae
  .epr_-7cq3xd
  .epr_6ocl7q {
  width: 100% !important;
  height: 100% !important;
}

.chat-bar {
  direction: "rtl";
  writing-mode: "vertical-rl";
}
@media screen and (max-width: 900px) {
  .logooo {
    display: block;
    /* position: absolute; */
    /* left: 2%; */
  }
  .mbl-nav-bar {
    margin-top: 35px !important;
  }
  .navbar-dis {
    justify-content: end;
  }
  .nav-mbl-links {
    display: flex !important;
    z-index: 99;
    background-color: #140c2d;
    padding-left: 10px;
    position: absolute;
    top: 50px;
    margin-left: 100%;
    transition: left 0.5s ease-in-out;
  }

  .nav-mbl-links.show {
    left: 0; /* Slide in */
  }

  .nav-mbl-links.hide {
    left: 100%; /* Slide out */
  }
}
@media screen and (max-width: 1690px) {
  .Match-cards-div {
    width: 62rem;
  }
}
@media screen and (max-width: 1279px) {
  .Match-cards-div {
    width: 42rem;
  }
}
@media screen and (max-width: 920px) {
  .Match-cards-div {
    width: 42rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mbl-btn {
    width: 220px !important;
  }
  .clapper-palyer {
    display: block !important;
  }
}
@media screen and (max-width: 670px) {
  .Match-cards-div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.video-player-wrapper {
  width: 100vw !important;
  height: 100vh !important;
}
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  opacity: 1 !important;
}
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  opacity: 1 !important;
}
.google-btn {
  width: 100% !important;
}
/* .searcCard {
  background-image: url(../src/Assets/Images/NFLHDFALLBACK.webp);
} */
.footer {
  width: 100%;
  height: 24vh;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
/* .main-home-page {
  position: relative;
  z-index: 99;
  overflow: visible;
} */

.carousel-indicators {
  display: none !important;
}
.fooer-bg-Img {
  width: 100%;
  /* height: 26vh; */
  background-image: url(./Assets//Images/footer-bg-item.png);
  overflow: auto;
}
.footer {
  overflow: auto;
}
.fooer-Img ul {
  display: flex;
  width: 30vw;
  /* border: 1px solid; */
  justify-content: space-between;
  margin-left: 350px;
  margin-top: 50px;
}
.fooer-Img li {
  text-decoration: none;
  list-style: none;
}
.fooer-Img li a {
  text-decoration: none;
  color: white;
  font-size: 18px;
}
.login-Bg {
  background-image: url(../src/Assets/Images/login-signup-bg-img.jpg);
  background-position: center;
  background-size: cover;
  opacity: 0.2;
}
.signup-Bg {
  background-image: url(../src/Assets/Images/login-signup-bg-img.jpg);
  background-size: cover;
  opacity: 0.9;
}
.ql-container.ql-snow {
  border: none !important;
}
.signup-Bg {
  width: 100%;
}
.labelTextColor {
  color: white;
}

/* Live button */
.animation-container {
  display: inline-block;
  position: relative;
}

.circular-div {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px;
  font-size: 10px;
  display: inline-block;
  animation: fadeIn 1s ease-in-out infinite; /* Add 'infinite' for an infinite animation */
  opacity: 0; /* Start with opacity set to 0 */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5; /* Optional: Set a mid-point opacity value if desired */
  }
  100% {
    opacity: 1;
  }
}
.team-icons:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.team-Logo-name {
  display: none;
  margin-top: -10px;
}

.team-icons:hover ~ .team-Logo-name {
  display: block;
  transition: 1s ease-in-out;
  height: 35px;
}
.online {
  width: max-content;
}
@media screen and (max-width: 1500px) {
  .score-card {
    min-width: 270px;
  }
}
@media screen and (max-width: 680px) {
  .card-con {
    width: 56vw;
  }
  .score-card {
    min-width: 270px;
    height: 200px;
  }
  .channle-img {
    height: 170px !important;
  }
  .nav-mbl-links {
    /* display: flex !important; */
    /* z-index: 99; */
    background-color: #140c2d;
    /* padding-left: 10px; */
    /* position: absolute !important; */
    /* top: 50px; */
    /* left: 100%; */
    transition: left 0.5s ease-in-out;
  }
}

@media screen and (max-width: 2600px) {
  .play-wrapper[data-poster] {
    width: 10% !important;
    margin: auto;
  }
  .skeleton-loading {
    width: 54%;
  }
  .footer-width {
    width: 65% !important;
    position: relative;
    top: 20%;
  }
  .detail-live-end {
    position: absolute;
    left: 45%;
  }
  .nav-menu-btn {
    display: none;
  }
  .tvChannel-head {
    width: 23vw;
  }
  .tvChannel-head2 {
    width: 24vw;
  }
  .banner-slide-card {
    margin-left: 30px;
  }

  .red-bar {
    width: 12.7vw;
  }
  .card-match {
    width: 65.7vw !important;
  }
  .score {
    visibility: hidden;
  }
  .score-card:hover .score {
    visibility: visible;
  }
  /* .teamOtherCards{
    margin-right: -200px;
  } */
  .Cardslider {
    width: 65% !important;
  }
  .main-banner-container {
    width: 65% !important;
  }
  .SearchCard {
    width: 65% !important;
  }

  .live {
    position: relative;
    margin-top: -50px;
  }
  .end {
    position: relative;
    margin-left: 10px;
  }

  /* .container{
    padding-left:20px ;
  } */
  .dash-headd {
    width: 75vw;
  }

  .mainSlider {
    height: 32rem;
  }
  .sliderHeader {
    width: 65%;
  }
  .ended {
    margin-top: -50px;
    margin-left: 0px;
  }
  .tem-img-box {
    width: 10vw;
    padding-top: 0px;
    margin-left: -75px;
    margin-top: -5px;
  }
  .team-img-box {
    width: 12vw;
    margin-left: -65px;
  }
  .skeleton-loading-home {
    width: 65% !important;
  }
}
@media screen and (max-width: 2500px) {
  .skeleton-loading {
    width: 57% !important;
  }
  .skeleton-loading-home {
    width: 65% !important;
  }
}
@media screen and (max-width: 2300px) {
  .skeleton-loading {
    width: 63% !important;
  }
}
@media screen and (max-width: 2200px) {
  .skeleton-loading {
    width: 64% !important;
  }
  .skeleton-loading-home {
    width: 65% !important;
  }
}
@media screen and (max-width: 2100px) {
  .skeleton-loading {
    width: 66% !important;
  }
  .skeleton-loading-home {
    width: 65% !important;
  }
}
@media screen and (max-width: 1980px) {
  .red-bar {
    width: 14.4vw;
  }
  .skeleton-loading-home {
    width: 73% !important;
  }
  .skeleton-loading {
    width: 73% !important;
  }
  .banner-text {
    bottom: -60% !important;
  }
  .footer-width {
    width: 73% !important;
    position: relative;
    top: 20%;
  }
  /* .main-home-page {
    height: 160vh;
  } */
  .Cardslider {
    width: 73% !important;
  }
  .main-banner-container {
    width: 73% !important;
  }
  .tvChannel-head {
    width: 25vw;
  }
  .tvChannel-head2 {
    width: 25vw;
  }

  .card-match {
    width: 73vw !important;
  }
  .sliderHeader {
    width: 73%;
  }
  .end {
    position: relative;
    margin-left: 10px;
  }
  .dash-headd {
    width: 82vw;
  }

  .watch-img {
    width: 67vw;
  }
  .teams-logo{
    height: 36rem !important;
  }
  .live-head {
    width: 67vw;
    border: 1px solid;
  }
  .banner-slidess {
    width: 68%;
  }
  .mainSlider {
    height: 31rem;
  }

  .tem-img-box {
    width: 12vw;
    padding-top: 0px;
    margin-left: -85px;
    margin-top: -5px;
  }
  .ended {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1820px) {
  .pay-cards {
    width: 1050px !important;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .skeleton-loading {
    width: 56% !important;
  }
}
@media screen and (max-width: 1520px) {
  .pay-cards {
    width: 670px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .skeleton-loading {
    width: 62% !important;
  }
  .affiliate-dash-main {
    justify-content: flex-start;
  }
  .affiliates-card {
    width: 47%;
  }
}
@media screen and (max-width: 1460px) {
  .skeleton-loading {
    width: 68% !important;
  }
}

@media screen and (max-width: 1360px) {
  .mainSlider {
    height: 30rem !important;
  }
  .skeleton-loading {
    width: 70% !important;
  }
}
@media screen and (max-width: 1305px) {
  .mainSlider {
    height: 28rem !important;
  }
  .skeleton-loading {
    width: 73% !important;
  }
}
@media screen and (max-width: 1295px) {
  .mainSlider {
    height: 27.5rem !important;
  }
}
@media screen and (max-width: 1265px) {
  .mainSlider {
    height: 26.5rem !important;
  }
}
@media screen and (max-width: 1210px) {
  .mainSlider {
    height: 25.5rem !important;
  }
  .chat-responsive {
    display: flex;
    flex-direction: column;
  }
  .live-chat-responsive {
    width: 100% !important;
  }
  .live-deatil-container {
    height: 45vh;
  }
  .chat-box-container {
    height: 93% !important;
  }
  .affiliate-dash-main {
    justify-content: flex-start;
    width: 90%;
  }
  .affiliate-invoice-table {
    width: 90%;
  }
  .affiliates-card {
    width: 47%;
  }
}
@media screen and (max-width: 1155px) {
  .mainSlider {
    height: 24rem !important;
  }
}
@media screen and (max-width: 1100px) {
  .mainSlider {
    height: 22.5rem !important;
  }
  .live-chat-responsive1 {
    width: 100%;
    height: 62vh !important;
  }
}
@media screen and (max-width: 1050px) {
  .mainSlider {
    height: 21.5rem !important;
  }
  .skeleton-loading-home {
    width: 99% !important;
  }
  .skeleton-loading {
    width: 63% !important;
  }
  .dash-header {
    height: 10vh !important;
  }
  .teams-logo{
    height: 20rem !important;
  }
}

@media screen and (max-width: 1000px) {
  .AdminBarLogo {
    display: none;
  }
  .ended-channel {
    top: -40px;
  }
  .nav-sider {
    border: 0px;
  }
  .main-home-page {
    height: 75rem !important;
  }

  .detail-live-end {
    position: absolute;
    left: 43%;
    margin-top: -8px;
  }
  .affiliate-dash-main {
    width: 97%;
    padding: 12px;
  }
  .affiliates-card {
    width: 46%;
    display: block !important;
  }
  .mainSlider {
    /* margin-top: 20px !important; */
    /* height: 40vh !important; */
  }

  .sliderHeader {
    width: 100%;
  }
  .tem-img-box {
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .tem-img {
    margin-top: -10px;
  }
  .dash-headd {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .headd {
    width: 25vw;
    margin-top: 10px;
  }
  .live {
    position: relative;
    margin-top: -40px;
  }
}
@media screen and (max-width: 855px) {
  .skeleton-loading {
    width: 40% !important;
  }
  .dash-header {
    height: 15vh !important;
  }
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.container1 {
  width: 43vmin;
  height: 18vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3em 0;
  position: relative;
  font-size: 16px;
  border-radius: 0.5em;
  background-color: #1c1c1e;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 85vw;
  }
  .container1 {
    height: 26vmin;
    width: 26vmin;
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    width: 90vw;
    flex-wrap: wrap;
    gap: 30px;
  }
  .footer-goole-img {
    height: 0px;
    margin-top: -20px;
  }
  .container {
    padding-left: 10px;
  }
  .footer-width {
    width: 98% !important;
    position: relative;
    top: 20%;
  }

  .google-btnn {
    width: 120px;
    height: 40px;
  }
  .history-table {
    padding: 10px;
    width: 60vw;
  }
  .container1 {
    width: calc(50% - 40px);
    height: 30vmin;
    font-size: 14px;
  }
  .dash-header {
    height: 15vh !important;
  }
}
@media screen and (max-width: 765px) {
  .dash-header {
    height: 36vh !important;
  }
  .text-height{
    height:  36vh !important;
  }
  .skeleton-loading {
    width: 90% !important;
  }
  .card-con-mbl {
    width: 87vw !important;
    margin-left: 0px !important;
  }
}
.headd {
  width: 25vw;
  margin-top: 10px;
}

@media screen and (max-width: 380px) {
  .banner-Buttons {
    margin-left: 0;
    margin: auto;
    position: relative;
    top: 20% !important;
    left: 0%;
    display: flex;
    flex-direction: column;
  }

  .text-height{
   height:  43vh !important;
  }
  .score-card {
    min-width: 330px;
    height: 200px;
  }
  .main-home-page {
    height: 71rem !important;
  }
  .dash-header {
    height: 34vh !important;
  }

  .cardd {
    width: 100px !important;
    height: 30px !important;
  }
  .pay-cardd {
    width: 100vw !important;
  }
  .new-footer-box {
    width: 100vw;
  }
  .pay-cardds {
    width: 93vw !important;
    height: 30vh;
  }
  .pay-card-box {
    width: 98vw;
    padding: 10;
  }
  .coupan-btn {
    margin-top: 10px;
  }
  .tem-icons {
    height: 35px !important;
    width: 35px !important ;
    /* margin-left: -20px; */
  }
  .tem-img-box {
    width: 100%;
  }
}

.dropdownActionButton {
  width: 250px !important;
}
.cardd {
  width: 100px !important;
  height: 30px !important;
}
.pay-cardd {
  width: 100vw !important;
}
.endedd {
  margin-left: 60px;
  margin-top: -20px !important;
}
.new-footer-box {
  width: 100vw;
}

.coupan-btn {
  margin-top: 10px;
}
.tem-icons {
  height: 35px !important;
  width: 35px !important ;
  /* margin-left: -20px; */
}
.tem-img-box {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .wrapper {
    gap: 15px;
  }
  .headd {
    width: 38vw;
    margin-top: 10px;
  }
  .for-mbl-btns {
    display: flex;
    flex-direction: column;
  }
  .theater-btn-mbl {
    width: 80px;
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .container1 {
    width: 70%;
    height: 25vmin;
    font-size: 8px;
  }
  .palyer-mbl {
    height: 25% !important;
  }
  /* .chat-bar {
    height: 22.5rem !important;
  } */
  .live-chat-responsive {
    width: 100%;
    height: 45% !important;
  }

  .banner-text {
    left: 24% !important;
    font-size: 24px !important;
    width: 25% !important;
    text-align: center !important;
    margin-top: 20px;
    text-shadow: -1px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .banner-textt {
    width: 100%;
    font-size: 20px;
    text-shadow: -1px 4px 2px rgba(0, 0, 0, 0.6);
    margin-left: 10px;
  }
  .sidebar-toggle {
    display: none;
  }
  .side-icon {
    display: none;
  }
  .admin-dash {
    left: 0% !important;
  }
  .tvChannel-head {
    width: 100% !important;
    height: 23vh !important;
  }
  .Category-Filter {
    width: 250px !important;
  }
  .search-title {
    width: 250px !important;
  }
  .Add-tv {
    width: 100px;
    height: 5vh !important;
  }
  .search-transactions {
    width: 450px !important;
    border: 1px solid;
    margin-bottom: 10px;
  }

  .input-feild {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 73vw;
  }
  .input-feild-image {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .input-feild-label {
    min-width: 50vw;
  }
  .edit-con {
    padding: 1rem;
    padding-left: 0.7rem;
  }
  .input-feild-drop {
    width: 75vw;
  }
  .main-user-container {
    display: flex;
    flex-direction: column;
  }
  .user-edit {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .user-info {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }
  .active-user-btn {
    margin-left: -50px;
  }
  .Useredit-side {
    width: 100%;
    height: 23vh !important;
    margin: 0;
    margin-top: 10px;
  }
  .user-transaction {
    margin-top: 10px;
  }
  .sub-type {
    width: auto;
    justify-content: space-around;
  }
  .main-page {
    flex-direction: column;
  }
  .left-side {
    width: 100%;
  }
  .signup-Bg {
    width: 100%;
  }
  .signup h1 {
    width: 80% !important;
    font-size: 26px !important;
  }

  /* .banner-Buttons{
  margin-left: 0;
  margin-top: -20px !important;
  margin: auto;
  position: relative;
  top: -30% ;
 left: 30%;
 display: flex;
 flex-direction: column;
 } */
  .post-Button {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .footer-flex {
    display: flex;
    flex-direction: column;
  }
  .footer {
    height: max-content;
  }
  .footer-title {
    font-size: 20px;
  }
  .box {
    margin-left: 15px;
  }
  .search-bar-for-transaction {
    width: 93% !important;
  }
}

@media screen and (max-width: 769px) {
  .main-profile-img {
    margin: auto;
  }
  .footer-tabs {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .fooer-bg-Img {
    height: 10.3rem;
  }
  .ended-channel {
    top: -50px;
  }
  .mbl-nav-bar {
    width: 300px;
    top: 40px;
    transition: left 0.2s ease-in-out;
  }
  .Edit-container {
    display: flex;
    flex-direction: column;
  }
  .live {
    position: relative;

    margin-top: -34px;
  }
  .end {
    position: relative;
    left: 10%;
  }

  .left {
    width: 100%;
  }
  .team-icons {
    margin-left: 0px !important;
  }
}
.input-feild {
  .left {
    width: 100%;
  }
  .input-feild {
    width: 75vw;
  }
  .banner-sliders {
    width: 60%;
    border: 1px solid;
  }
  .input-feild-label {
    width: 20vw;
  }
  .banner-Buttons {
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 55%;
    display: flex;
    margin-left: 10px;
    margin-top: 55px;
  }
  .team-namess {
    display: none;
    margin-top: -10px;
  }

  .post-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
    margin-left: 5px;
  }
  .pre-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .save-btn {
    margin-left: 450px;
  }
  .edit-con {
    left: 10%;
  }
  .layer-icon {
    display: block;
  }
  .main-page {
    flex-direction: column !important;
  }
  .left-side {
    width: 100%;
    height: 50vh;
  }
  .signup-Bg {
    width: 100%;
    height: 40vh;
  }
  .signup {
    width: 100%;
    height: 40vh;
  }
  .signup img {
    margin-top: 30px;
  }
  .signup h1 {
    width: 50%;
    font-size: 30px;
  }
  .right-side {
    width: 100%;
    height: 100vh !important;
  }

  /* .banner-Buttons{
    margin-left: 210px ;
    margin-top: -10px;
    margin: auto;
    width: 34vw !important;
    position: relative;
    top: -20%;
   } */
  .mainSlider {
    height: 58vh !important;
  }
  .sliderHeader {
    width: 95% !important;
  }

  .contact-form {
    flex-direction: column;
    /* width: 100%; */
  }
  .footer {
    height: max-content;
  }
  .Add-tv {
    width: 120px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px !important;
  }
  .banner-text {
    margin-left: 500px;
    font-size: 38px;
    margin-left: 35px;
    /* width: 25% !important; */
    text-align: center;
    margin-top: -9%;
    text-shadow: -1px 4px 2px rgba(0, 0, 0, 0.6);
  }
}
@media screen and (max-width: 430px) {
  .tem-p {
    font-size: 10px !important;
  }
  .card-con {
    width: 80vw;
  }
  .search-bar {
    width: 98vw;
  }
  .input-label1 {
    width: 76vw;
  }
  .bg-tab-mb {
    height: 29vh !important;
  }
  .userhisotryy {
    width: 100%;
    height: 50vh;
  }
  .main-user-container {
    width: 106vw;
    left: 4% !important;
  }
  .user-info {
    padding: 0 !important;
  }

  .address-ip {
    max-width: 45vw;
  }
  .edit-con {
    left: 7% !important;
  }
  .left {
    width: 100% !important;
  }
  .tv-logo {
    width: 17vw;
  }
  .deatil-container {
    height: 30vh;
    margin-top: 0px !important;
  }
  .live {
    position: relative;
    margin-top: -40px;
  }

  .tem-img {
    width: 35px !important;
    height: 35px !important;
  }
  .edit-con {
    width: 100vw;
  }
  .edit-contain {
    margin-left: -30px;
  }

  .team-namess {
    display: none;
    margin-top: 32px;
  }
  .history-div {
    width: 95%;
  }
  .watch-img {
    height: 35vh !important;
  }
  .text-footer a {
    font-size: 11px;
  }
  .text-footer {
    width: 92vw !important;
  }
  .google-btnn {
    width: 120px;
    height: 40px;
  }
  .app-btn {
    width: 90vw !important;
  }
  .tem-img-box {
    width: 35vw;
    padding-top: 0px;
    padding-left: 20px;
    margin-left: 0px;
    margin-top: -4px;
  }
  .ended {
    /* margin-left: 70px; */
    margin-top: -45px !important  ;
    left: 20%;
  }
}
@media screen and (max-width: 420px) {
  .banner-text {
    margin-top: -14% !important;
  }
  .mbl-img-banner {
    height: 13rem !important;
  }
  /* .mbl-slider-bg-color {
    height: 16rem;
  } */
  .teams-logo{
    height: 50vh !important;
  }

  .watch-img {
    height: 30vh !important;
  }

  .tem-icons {
    height: 30px !important;
    width: 30px !important ;
    /* margin-left: -20px; */
  }
  .palceandtimeheight {
    height: 5vh;
  }
  /* .dash-card {
    width: 100vw;
    height: 30vh !important;
    margin-right: 10px;
  } */
  .dash-main {
    width: 100%;
    padding: 10px;
  }
  .bg-imgg {
    width: 100vw;
    height: 28vh;
  }
  .tem-p {
    font-size: 10px !important;
  }
  .banner-Buttons {
    width: 52vw !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 55% !important;
    display: flex;
    margin-left: 10px;
    margin-top: 55px;
  }
  .history-table {
    padding: 10px;
    width: 100vw !important;
    margin-bottom: 10px;
  }
  .post-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .pre-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .sliderHeader {
    width: 99% !important;
  }
  .tem-img-box {
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .tem-img {
    margin-top: -10px;
  }
  .card-slider1 {
    width: 200.5px !important;
    height: 100px !important;
  }
  .banner-slidess {
    width: 95vw;
  }
  .banner-slide-card {
    padding: 0;
    margin-left: 0;
    width: 128vw !important;
  }
  .bg-img {
    height: 30vh;
  }
  .ended {
    margin-top: -75px;

    left: -2%;
  }
  .teams-logo{
    height: 20rem !important;
  }
}
@media screen and (max-width: 380px) {
  .banner-Buttons {
    width: 55vw !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 55% !important;
    display: flex;
    margin-left: 10px;
    margin-top: 55px;
  }
  .live {
    position: relative;
    margin-top: -40px;
  }

  .post-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 33px !important;
  }
  .pre-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .google-btnn {
    width: 120px;
    height: 40px;
  }
  .team-namess {
    display: none;
    margin-top: 33px !important;
  }
  .text-footer a {
    font-size: 11px;
  }
  .footer-copy {
    margin-top: 10px !important;
  }
  .banner-text {
    margin-top: -14% !important;
  }
  /* .app-btn{
    margin-top: 30px !important;
  } */
  .tem-p {
    font-size: 10px !important;
  }
  /* .pay-texts {
    margin-left: -80px;
  } */
  .plan-page-text {
    width: 100vw;
    padding: 20px;
  }
  .card-match {
    margin-left: -4px !important;
  }

  .team-icons {
    height: 45px !important;
  }
  /* .img-icon {
    width: 12px;
    height: 12px;
  } */
  .headd {
    width: 43vw !important;
    margin-top: 10px;
  }
  .mbl-bg-img {
    height: 26.5vh !important;
  }

  .team-p {
    font-size: 16px;
  }
  .tem-img-box {
    width: 40vw;
    padding-top: 0px;
    padding-left: 20px;
    margin-left: 0px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 650px) {
  .tem-img-box {
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .ended {
    margin-top: -55px;
    margin-left: 50px;
  }
  .tem-img {
    margin-top: -10px;
  }
  .affiliate-dash-main {
    width: 97%;
    padding: 12px;
  }
  .affiliates-card {
    width: 100% !important;
    display: block !important;
  }
}
@media screen and (max-width: 540px) {
  .footer-flex {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .nav-a {
    color: rgb(255, 255, 255) !important;
    font-weight: 700;
    font-size: 18px;
    padding-left: 20px;
  }
  .nav-aa {
    position: relative;
    width: 100vw;
    /* border-bottom: 1px solid; */
    /* right: 18%; */
  }
  .nav-aaa {
    position: relative;
    width: 100vw;
    /* right: 18%; */
  }
  .text-btnn {
    font-size: 13px;
  }
  .app-btn {
    width: 100%;
    justify-content: space-between;
  }
  .headd {
    width: 35vw;
    margin-top: 10px;
  }
  .search-transactions {
    margin-bottom: 10px;
  }
  .watch-img {
    width: 100vw;
    height: 50vh;
  }
  .sidddee {
    height: 7vh;
    border: 1px solid;
  }
  .layer-icon {
    display: none !important;
  }
  .side-icon {
    display: none;
  }
  .tvChannel-head {
    width: 100% !important;
    height: 25vh !important;
  }
  .Add-tv {
    width: 120px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 920px) {
  .signup-Bg {
    width: 100%;
    /* height: 50vh; */
  }
  .icons-top {
    margin-top: 150px;
  }

  /* .nav-menu-btn {
    display: block;
  } */
  .nav-a {
    color: rgb(255, 255, 255) !important;
    font-weight: 700;
    font-size: 18px;
  }

  .card-con {
    width: 90vw;
  }
  .nfl-view {
    margin-top: 0px;
  }
  .pay-cardds {
    width: 100% !important;
    height: 20vh;
  }
  .pay-card-box {
    width: 70vw;
    padding: 10;
  }
  .bg-tab-mb {
    height: 37vh;
  }
  .end {
    position: relative;
    margin-left: 40px;
  }
  .card-live-end {
    position: relative;
  }

  .team-namess {
    display: none;
    margin-top: -10px;
  }
  .team-icons {
    margin-left: -80px;
  }
  .watch-img {
    width: 95vw;
  }
  .footer-flex {
    flex-direction: column;
    width: 100vw;
  }
  .text-footer {
    width: 69vw;
  }
  .google-btnn {
    width: 120px;
    height: 40px;
  }
  .app-btn {
    width: 74vw;
    justify-content: flex-start;
  }
  .history-table {
    padding: 10px;
    width: 68vw;
  }
  .dash-card {
    width: 100vw;
    height: 22vh;
    margin-right: 10px;
  }
  .dash-main {
    width: 100%;
    padding: 10px;
  }
  .sliderHeader {
    width: 100%;
  }

  .footer-goole-img {
    height: 0px;
    margin-top: -20px;
  }
  .pay-cards {
    width: 100%;
    justify-content: center;
  }
  .contact-form {
    flex-direction: column;
    width: 100vw;
  }
  .contact-feild {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-info {
    width: 100%;
  }
  .admin-dash {
    position: relative;
    left: 7% !important;
  }
  .dash-card {
    margin-left: 13px;
  }
  .banner-text {
    margin-left: 150px;
    font-size: 38px;
    /* width: 25% !important; */
    text-align: center;
    margin-top: -9%;
    text-shadow: -1px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .banner-Buttons {
    width: 27vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 62%;
    display: flex;
    margin-left: 10px;
    margin-top: 55px;
  }
  .post-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .pre-Button {
    margin-top: 0px !important;
    width: 100px !important;
    height: 35px !important;
  }
  .layer-icon {
    display: block;
    margin-top: 10px;
  }
  .side-icon {
    width: 6vw !important;
  }
  .footer {
    height: max-content;
  }
  .tvChannel-head {
    width: 100% !important;
    height: 10vh;
  }
  .Category-Filter {
    width: 200px !important;
    margin-left: 20px;
  }
  .search-title {
    width: 200px;
    margin-left: 20px;
  }
  .Add-tv {
    width: 120px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px !important;
  }
  .table-search-user {
    margin-top: 10px !important;
  }
  .banner-slidess {
    width: 95vw;

    height: 32vh;
  }
  /* .banner-slide-card {
    padding: 0;
    margin-left: 0;
    width: 128vw !important;
  } */
  .live-head {
    width: 95vw;
  }
}
@media screen and (max-width: 920px) {
  .layer-icon {
    display: block;
    margin-top: 10px;
  }
  .main-user-container {
    display: flex;
    flex-direction: column;
  }
  .user-edit {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .user-info {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }
  .active-user-btn {
    margin-left: -50px;
  }
  .Useredit-side {
    width: 100%;
    height: 18vh;
    margin: 0;
    margin-top: 10px;
  }
  .user-transaction {
    margin-top: 10px;
  }
  .sub-type {
    width: auto;
    justify-content: space-around;
  }

  .side-icon {
    width: 5.5vw !important;
  }
  .box {
    left: 12% !important;
  }
  .Category-Filter {
    width: 220px !important;
    margin-left: 20px;
  }
  .admin-dash {
    position: relative;
    left: 6.5% !important;
  }
  .dash-card {
    margin-left: 13px;
  }
  .search-title {
    width: 280px;
    margin-left: 20px;
    margin-top: 10px !important;
  }
  .Add-tv {
    width: 120px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px !important;
  }
  .tem-img-box {
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .ended {
    margin-top: -40px;
    margin-left: 20px;
  }
  .tem-img {
    margin-top: -10px;
  }
}
@media screen and (max-width: 900px) {
  .nav-menu-btn {
    display: block;
  }
  .nav-a {
    color: rgb(255, 255, 255) !important;
    font-weight: 700;
    font-size: 18px;
  }
  .support-icon {
    display: none !important;
  }
  .Support-text {
    display: block !important;
  }
}
@media screen and (max-width: 1768px) {
  .main-home-page {
    height: 100rem;
  }
  /* .edit-con{
    width: 100vw;
  } */
}

/* Card media QUeerry */
@media screen and (max-width: 1568px) {
  .left-side {
    width: 50%;
  }
  .tvChannel-head2 {
    width: 30vw;
  }
  .main-home-page {
    height: 100rem;
  }
  /* .edit-con{
    width: 100vw;
  } */
}
@media screen and (max-width: 1368px) {
  .banner-btnss {
    margin-top: -190px !important;
  }
  .tvChannel-head2 {
    width: 35vw;
  }
  .banner-Buttons {
    width: 20vw !important;
  }
  .banner-slide-card {
    margin-left: 0px;
  }
  .card-match {
    width: 76vw !important;
  }
  .dash-main {
    width: 100%;
  }
  .dash-card {
    width: 24vw;
    height: 27vh;
  }
}
@media screen and (max-width: 1290px) {
  .dash-main {
    width: 100%;
  }
  .dash-card {
    width: 24vw;
    height: 30vh;
  }
  .banner-slide-card {
    width: 100rem;
  }
}
@media screen and (max-width: 1190px) {
  .dash-main {
    width: 100%;
  }
  .dash-card {
    width: 24vw;
    height: 30vh;
  }
  .banner-slide-card {
    width: 92rem;
  }
}
@media screen and (max-width: 1024px) {
  .banner-sign-text {
    width: 40vw;
  }
  .sign-text {
    margin-top: -30px;
  }
  .mbl-bg-img {
    height: 35vh;
    width: 100%;
  }
  .main-home-page {
    height: 90rem;
  }
  .banner-slide-card {
    width: 85rem;
  }

  .banner-Buttons {
    width: 27vw !important;
    margin-top: 120px;
  }
  .banner-textt {
    margin-top: 5%;
  }
  .sliderHeader {
    width: 100%;
  }
  .Cardslider {
    width: 100% !important;
  }
}
@media screen and (max-width: 960px) {
  .banner-slide-card {
    width: 80rem;
  }
  .deatil-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 860px) {
  .banner-slide-card {
    width: 65rem;
  }
  .boox {
    width: 90% !important;
  }
}
@media screen and (max-width: 760px) {
  .banner-slide-card {
    width: 60rem;
  }
}
@media screen and (max-width: 750px) {
  .banner-slide-card {
    width: 55rem;
  }
}
@media screen and (max-width: 700px) {
  .banner-slide-card {
    width: 51rem;
  }
}
@media screen and (max-width: 600px) {
  .banner-slide-card {
    width: 45rem;
  }
}
@media screen and (max-width: 550px) {
  .banner-slide-card {
    width: 42rem;
  }
  .fooer-bg-Img {
    height: 12rem;
  }
  .main-home-page {
    height: 70rem !important;
  }
}
@media screen and (max-width: 500px) {
  .banner-slide-card {
    width: 36rem;
  }
}
@media screen and (max-width: 750px) {
  .pages {
    width: 98vw;
  }
  /* .contact-feild {
    width: 98vw;
    margin-left: -85px;
  } */
  .contact-feild-form {
    /* width: 97vw; */
    /* margin-left: -46px !important; */
  }
  .contact-form {
    width: 100vw;
  }

  /* .contact-info {
    width: 98vw;
    margin-left: -85px !important;
  } */
}

@media screen and (max-width: 720px) {
  .card-con {
    width: 100vw;

    margin-left: 20px;
    padding: 0;
  }
  .signup h1 {
    width: 80% !important;
    font-size: 26px !important;
  }
  .contain-card {
    width: 100vw;
  }
  .banner-textt {
    margin-top: 0%;
  }
  .banner-Buttons {
    width: 27vw !important;
    margin-top: 45px;
  }
  .main-page {
    flex-direction: column !important;
  }
  .left-side {
    width: 100%;
  }

  .right-side {
    width: 100%;
  }
  .sign-text {
    margin-top: 30px !important;
  }
  .slider-head-text {
    font-size: 18px !important;
  }
  .sliderHeader {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 645px) {
  .card-con {
    width: 47vw;
  }
  .small-screen{
    width: 100%;
  }
  .deatil-container {
    height: 20rem;
    margin-top: 0px !important;
  }
  .error-box {
    width: 80vw;
    /* margin-left: -80px; */
  }
  .card-error-fix {
    margin-left: -52px;
  }
  .pay-texts {
    /* width: 93vw; */
    margin-left: -90px;
  }
  .pay-card-box {
    width: 80vw;
  }
  .pay-cardds {
    height: 25vh;
  }
  .pages {
    width: 98vw;
  }
}
@media screen and (max-width: 550px) {
  .card-con {
    width: 55vw;
  }
  .pages {
    width: 98vw;
  }
  .footer-width {
    width: 100%;
    padding: 5px;
    position: relative;
    top: 0;
  }
  .boox {
    width: 100% !important;
  }
  .mbl-bg-img {
    height: 28vh;
  }
  .bg-tab-mb {
    height: 30vh;
  }
  .dash-card {
    width: 100vw;
    height: 30vh;
  }
}

@media screen and (max-width: 490px) {
  .card-con {
    width: 65vw;
  }
  .pages {
    width: 98vw;
  }
  .main-home-page {
    height: 78.5rem !important;
  }
}
@media screen and (max-width: 450px) {
  .card-con {
    width: 78vw;
  }
  .banner-Buttons {
    width: 50vw !important;
  }
  .pay-cardds {
    height: 28vh;
  }
  .plan-page-text {
    width: 93vw;
  }
  .detail-live-end {
    position: absolute;
    left: 43%;
    margin-top: -8px;
  }
  /* .team-Logo-name {
    margin-top: 35px;
  } */
}
@media screen and (max-width: 390px) {
  .card-con {
    width: 85vw;
  }
  .banner-Buttons {
    width: 60vw !important;
  }
  .boox {
    width: 90% !important;
  }

  .pay-cardds {
    height: 28vh;
    width: 83vw !important;
    margin-left: -15px;
  }
  .main-home-page {
    height: 64rem !important;
  }
  .plan-page-text {
    width: 100vw;
  }
  .detail-live-end {
    position: absolute;
    left: 43%;
    margin-top: 28px;
  }
  .team-Logo-name {
    margin-top: 35px;
  }
  .mbl-img-banner {
    height: 12.7rem !important;
  }
}
/* Break points for card Details slider */
@media screen and (max-width: 1168px) {
  .deatils-carddd {
    width: 76%;
  }
  .details-icons {
    margin-top: 200px !important;
  }
  .live-deatil-container {
    width: 100%;
  }
}
@media screen and (max-width: 1120px) {
  .deatils-carddd {
    width: 73%;
  }
}
@media screen and (max-width: 1080px) {
  .deatils-carddd {
    width: 70%;
  }
  .details-icons {
    margin-top: 250px !important;
  }
}
@media screen and (max-width: 880px) {
  .deatils-carddd {
    width: 65%;
  }
}
@media screen and (max-width: 860px) {
  .deatils-carddd {
    width: 79%;
  }
}
@media screen and (max-width: 835px) {
  .deatils-carddd {
    width: 75%;
  }
}
@media screen and (max-width: 785px) {
  .deatils-carddd {
    width: 71%;
  }
  .live-deatil-container {
    height: 35rem;
  }
  .details-icons {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 755px) {
  .deatils-carddd {
    width: 80%;
  }
  .chat-responsive {
    display: block !important;
    justify-content: space-between;
  }
}
@media screen and (max-width: 675px) {
  .deatils-carddd {
    width: 75%;
  }
  .live-deatil-container {
    height: 20rem;
  }
}
/* Live Chat Responsiveness */
/* small tabs + mobile view */

@media screen and (max-width: 1020px) {
  .chat-responsive {
    display: flex;
    flex-direction: column;
  }

  .liveChat-box {
    width: 100% !important;
    background-color: red;
  }
  .chat-box-height {
    min-height: 29rem !important;
  }
  .inner-chat-box-height {
    max-height: 26rem !important;
  }
  .chat-scroll {
    max-height: 24rem !important;
  }
  /* #videoPlayer [data-player] {
    width: 100% !important;
    height: 100vh !important;
  } */
  /* .videoplayer [data-player] {
    width: 100% !important;
    height: 100vh !important;
  } */
  .palyer-mbl {
    height: 35%;
  }
  .live-chat-responsive {
    width: 100%;
    /* height: 55% !important; */
    justify-content: flex-start !important;
  }
  .chat-mbl-box {
    height: 100vh;
  }
}
@media screen and (max-width: 2500px) {
  .chat-responsive {
    display: flex;
  }
  .chat-box-height {
    min-height: 61rem !important;
  }
  .inner-chat-box-height {
    max-height: 58rem !important;
  }
  .chat-scroll {
    max-height: 56rem !important;
  }
  /* .input-1 {
    width: 70% !important;
  }
  .input-2 {
    width: 26% !important;
  } */
  .teams-logo{
    height: 35rem !important;
  }
}
@media screen and (max-width: 2400px) {
  .input-1 {
    width: 70% !important;
  }
  .input-2 {
    width: 23% !important;
  }
}
@media screen and (max-width: 2400px) {
  .chat-box-height {
    min-height: 59rem !important;
  }
  .inner-chat-box-height {
    max-height: 56rem !important;
  }
  .chat-scroll {
    max-height: 54rem !important;
  }
}
@media screen and (max-width: 2300px) {
  .chat-box-height {
    min-height: 56.5rem !important;
  }
  .inner-chat-box-height {
    max-height: 53rem !important;
  }
  .chat-scroll {
    max-height: 51rem !important;
  }
}
@media screen and (max-width: 2200px) {
  .chat-box-height {
    min-height: 54.5rem !important;
  }
  .inner-chat-box-height {
    max-height: 51rem !important;
  }
  .chat-scroll {
    max-height: 50rem !important;
  }
}
@media screen and (max-width: 2100px) {
  .chat-box-height {
    min-height: 52rem !important;
  }
  .inner-chat-box-height {
    max-height: 49rem !important;
  }
  .chat-scroll {
    max-height: 47.5rem !important;
  }
}
@media screen and (max-width: 2000px) {
  .chat-box-height {
    min-height: 49.6rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 45rem !important;
  }
}
@media screen and (max-width: 1980px) {
  .chat-box-height {
    min-height: 47.5rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  /* #videoPlayer [data-player] {
    height: 98vh !important;
  } */
  .chat-scroll {
    max-height: 42rem !important;
  }
}
@media screen and (max-width: 1900px) {
  .chat-box-height {
    min-height: 47.6rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 42rem !important;
  }
}
@media screen and (max-width: 1800px) {
  .chat-box-height {
    min-height: 44.5rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 40rem !important;
  }
  .msg-text {
    width: 300px !important;
  }
  .banner-text-container {
    width: 540px !important;
  }
}
@media screen and (max-width: 1700px) {
  .chat-box-height {
    min-height: 42.3rem !important;
  }
  .banner-img {
    height: 420px !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 37.7rem !important;
  }
  .card-spacing {
    margin-top: 0px !important;
  }
  .msg-text {
    width: 280px !important;
  }
}
@media screen and (max-width: 1600px) {
  .chat-box-height {
    min-height: 39.7rem !important;
  }
  .banner-img-text {
    font-size: 36px !important;
    line-height: 45px !important;
  }
  .banner-para-text {
    font-size: 20px !important;
  }
  .banner-img {
    height: 375px !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 34.7rem !important;
  }
  .msg-text {
    width: 260px !important;
  }
}
@media screen and (max-width: 1500px) {
  .chat-box-height {
    min-height: 37rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 32.3rem !important;
  }
  .input-1 {
    width: 73% !important;
  }
  .input-2 {
    width: 25% !important;
  }
  .msg-text {
    width: 240px !important;
  }
}
@media screen and (max-width: 1400px) {
  .chat-box-height {
    min-height: 34.6rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 30rem !important;
  }
  .input-1 {
    width: 70% !important;
  }
  .input-2 {
    width: 27% !important;
  }
  .banner-btn {
    width: 300px;
  }
  .banner-text-container {
    width: 480px !important;
  }
  .banner-img-text {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  .banner-para-text {
    font-size: 18px !important;
  }
  .msg-text {
    width: 220px !important;
    font-size: 12px;
  }
  .banner-img {
    height: 335px !important;
    width: 950px !important;
  }
  .input-box {
    bottom: 10px !important;
  }
  .teams-logo{
    height: 26rem !important;
  }
}
@media screen and (max-width: 1300px) {
  .chat-box-height {
    min-height: 32.3rem !important;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .chat-scroll {
    max-height: 28rem !important;
  }
  .input-1 {
    width: 70% !important;
  }
  .input-2 {
    width: 28% !important;
  }
  .msg-text {
    width: 190px !important;
    font-size: 12px;
  }
  .input-box {
    bottom: 10px !important;
  }
  .inputcc {
    width: 29px !important;
    height: 29px !important;
  }
  .icon-input {
    height: 15px !important;
  }

  .emoji-box {
    /* width: 250px !important; */
    top: -1050% !important;
    left: -1000% !important;
  }
  .banner-img-text {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  .banner-para-text {
    font-size: 18px !important;
  }
  .banner-img {
    height: 315px !important;
  }
  .banner-btn {
    width: 300px;
  }
}
@media screen and (max-width: 1290px) {
  .main-banner-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .banner-text-container {
    width: 100% !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-bottom: 28px;
  }
  .banner-img-text {
    font-size: 42px !important;
    line-height: 50px !important;
  }
  .banner-para-text {
    font-size: 24px !important;
  }
  .banner-img {
    height: 490px !important;
    width: 860px !important;
    margin: auto;
  }
  .banner-btn {
    margin: auto !important;
    width: 350px !important;
  }
}
@media screen and (max-width: 1200px) {
  .chat-box-height {
    min-height: 30rem !important;
  }
  .mbl-chat {
    width: 100%;
  }
  .inner-chat-box-height {
    max-height: 47rem !important;
  }
  .responsive-container {
    height: 59vh !important;
  }
  .chat-scroll {
    max-height: 25rem !important;
  }
  /* [data-player] {
    width: 100% !important;
    height: 50vh !important;
  } */
  .live-chat-responsive1 {
    width: 100%;
  }
  .input-1 {
    width: 90% !important;
  }
  .input-2 {
    width: 8% !important;
  }

  .msg-text {
    width: 170px !important;
    font-size: 12px;
  }
  .teams-logo{
    height: 22rem !important;
  }
}
@media screen and (max-width: 1120px) {
  /* .live-chat-responsive {
    margin-top: 8px !important;
  } */
}
@media screen and (max-width: 1020px) {
  .input-1 {
    width: 90% !important;
  }
  .input-2 {
    width: 10% !important;
  }
  .live-chat-responsive {
    margin-top: 0px !important;
  }
  .live-chatr-footer {
    position: relative !important;
  }
}
@media screen and (max-width: 870px) {
  .main-banner-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .banner-text-container {
    width: 100% !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-bottom: 28px;
  }
  .banner-img-text {
    font-size: 42px !important;
    line-height: 50px !important;
  }
  .banner-para-text {
    font-size: 24px !important;
  }
  .banner-img {
    height: 480px !important;
    width: 100% !important;
    margin: auto;
  }
  .banner-btn {
    margin: auto !important;
    width: 350px !important;
  }
}
@media screen and (max-width: 760px) {
  .main-banner-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .banner-text-container {
    width: 98% !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-bottom: 28px;
  }
  .banner-img-text {
    font-size: 30px !important;
    line-height: 40px !important;
    font-weight: 500 !important;
  }
  .banner-para-text {
    font-size: 16px !important;
  }
  .banner-img {
    height: 400px !important;
    width: 95% !important;
    margin: auto;
  }
  .banner-btn {
    margin: auto !important;
    width: 115px !important;
    height: 38px !important;
  }
}
@media screen and (max-width: 645px) {
  /* .error-box{
    width: 91vw !important;
    margin-left: -90px;
  } */
  .plan-cards-box {
    width: 93vw;
  }
}
@media screen and (max-width: 580px) {
  .banner-img {
    height: 330px !important;
    width: 95% !important;
    margin: auto;
  }
  .input-1 {
    width: 80% !important;
  }
  .input-2 {
    width: 20% !important;
  }
  .palyer-mbl {
    height: 30%;
  }
  /* [data-player] {
    width: 100% !important;
    height: 40vh !important;
  } */
}
@media screen and (max-width: 480px) {
  /* [data-player] {
    width: 100% !important;
    height: 30vh !important;
  } */
  .chat-box-container {
    height: 100% !important;
  }
  .live-chat-responsive {
    height: 60vh !important;
  }
  .banner-img {
    height: 300px !important;
    width: 90% !important;
    margin: auto;
  }
}
@media screen and (max-width: 420px) {
  .live-chat-responsive {
    height: 63vh !important;
  }
  .banner-img {
    height: 210px !important;
    width: 90% !important;
    margin: auto;
  }
  .mbl-chat-font {
    font-size: 12px !important;
  }
  .teams-logo{
    height: 20rem !important;
  }
}
@media screen and (max-width: 400px) {
  .input-1 {
    width: 75% !important;
  }
  .input-2 {
    width: 23% !important;
  }
  .theater-btn {
    margin-left: 10px;
  }
  .emoji-box {
    left: -900% !important;
    width: 270px !important;
  }
  .cards-laoder {
    width: 75vw;
  }
  .pay-cards {
    margin-left: -52px;
  }
  .error-box {
    width: 75vw;
    /* margin-left: -80px; */
  }
  .payment-skelton{
    width: 100%
  }
  .live-chat-responsive {
    height: 65vh !important;
  }
  .mbl-chat-font {
    font-size: 12px !important;
  }
  .planCardSkeleton {
    width: 100%;
  }
}
/* toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
