.logo {
  width: 145px;
  height: 35px;
}
//  a:hover{
//     text-decoration: none;
//     color: rgb(25, 5, 204);
// }

// li:hover a::before,
// li:active a::before {
//   background-color: rgb(73, 73, 250);
// }

// .active {

//     border-bottom: 3px solid rgb(73, 73, 250);
//     color: rgb(25, 5, 204);
//     cursor: pointer;
//     position: relative;

// }
.nav-Btn:hover {
  background-color: rgb(228, 167, 2);
  transition: 0.3s ease-in-out;
}

.Sub-Icon {
  width: 40px;
  height: 35px;
  background-color: rgb(228, 167, 2);
}
.Sub-Icon:hover {
  background-color: rgb(73, 73, 250);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.search-Icon {
  width: 35px;
  height: 35px;
  background-color: rgb(73, 73, 250);
}

.search-Icon:hover {
  background-color: rgb(228, 167, 2);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.nav-Icons {
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 30px;
  height: 3vh;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.dropdown {
  margin-left: -140px;
  width: 200px;
  height: auto;
  margin-top: 10px;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.dropdown-content li {
  border-bottom: 1px solid grey;
}

.dropdown-content a {
  color: grey;
  width: 100%;
}

// .css-6hp17o-MuiList-root-MuiMenu-list {
//       background-color: #00022b;
// }
// .css-6hp17o-MuiList-root-MuiMenu-list{
//   height: 35vh !important;
// }
.css-r6ewbb-MuiToolbar-root {
  background: #0d0620 !important;
  box-shadow: -1px 6px 8px -6px rgba(255, 255, 255, 0.45) !important;
}

@media screen and (max-width: 480px) {
  .logo {
    width: 100px;
    height: 25px;
  }
}
